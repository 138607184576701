<template>
  <div :class="$style.add">
    <div :class="$style.info">
      <p :class="$style.title">作者信息</p>
      <div :class="$style.box">
        <div :class="$style.left">
          <span :class="$style.required">*</span>
          作者姓名
        </div>
        <div :class="$style.right">
          <BjInput v-model="data.real_name" allow-clear placeholder="请输入作者姓名" />
          <p class="tip mb-20">请输入作者姓名，作者姓名会显示到本作者创作的内容页面。</p>
        </div>
      </div>
      <div :class="$style.box">
        <div :class="$style.left">
          <span :class="$style.required">*</span>
          作者头像
        </div>
        <div :class="$style.right">
          <uploadAvatar :src="data.avatar" @complete="avatarChange" />
          <p class="tip mb-20 mt-10">作者头像建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">主页封面</div>
        <div :class="$style.right">
          <uploadAvatar :src="data.page_cover" @complete="coverChange" />
          <p class="tip mb-20 mt-10">主页封面建议尺寸780px*440px；大小不超过2M；格式支持JPG、PNG。</p>
        </div>
      </div>

      <div :class="$style.box">
        <div :class="$style.left">个性签名</div>
        <div :class="$style.right">
          <a-textarea v-model="data.personality" :auto-size="{ minRows: 4 }" placeholder="请输入作者的个性签名..." />
        </div>
      </div>
    </div>

    <div :class="$style.relation">
      <p :class="$style.title">关联账号</p>
      <div :class="$style.box">
        <div :class="$style.left">选择账号</div>
        <div :class="$style.right">
          <BjSelect
            v-model="data.account_id"
            key-field="id"
            allow-clear
            placeholder="请选择本作者关联的系统账号"
            value-field="master_id"
            label-field="account"
            show-all
            :options="userData"
          />
          <p class="tip">关联完成后，系统用户登录文化云创作内容将会以本作者名义发布。</p>
        </div>
      </div>
    </div>
    <div :class="$style.foot">
      <BjButton type="primary" @click="onSave()">
        <i class="ri-save-line left" />
        立即保存
      </BjButton>
      <BjButton :class="$style.clear" class="btn-default" @click="onClear()"> 清空信息 </BjButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import uploadAvatar from '@/components/uploadAvatar'
import { systemService } from '@/service'

const service = new systemService()

export default {
  name: 'Add',
  components: {
    uploadAvatar,
  },
  data() {
    return {
      data: {
        real_name: null, // 作者姓名
        avatar: null, // 作者姓名
        page_cover: null, // 主页封面
        personality: null, // 个性签名
        account_id: [], // 关联账号ID
      },
      userData: [],
    }
  },
  computed: {
    ...mapState(['token']),
  },
  created() {
    if (this.$route.params.id === 'add') {
      this.init()
    } else {
      this.getInfo()
    }
    this.getUserList()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.infoAuthor({
          id: this.$route.params.id,
        })
        this.data = data
      } catch (e) {}
    },
    init() {
      this.data = {
        real_name: null, // 作者姓名
        avatar: null, // 作者姓名
        page_cover: null, // 主页封面
        personality: null, // 个性签名
        account_id: [], // 关联账号ID
      }
    },
    onClear() {
      this.data.real_name = null
      this.data.avatar = null
      this.data.page_cover = null
      this.data.personality = null
      this.data.account_id = []
    },
    async onSave() {
      try {
        this.data.id ? await service.saveAuthor(this.data) : await service.createAuthor(this.data)
        this.$router.push({
          name: 'systemAuthor',
        })
      } catch (e) {}
    },
    async getUserList() {
      const { data } = await service.accountList({
        no_page: 1,
      })
      this.userData = data
    },
    avatarChange(e) {
      this.data.avatar = e
    },
    coverChange(e) {
      this.data.page_cover = e
    },
  },
}
</script>

<style lang="less" module>
.add {
  color: #000;

  :global {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .info {
    padding: 20px;
    background: #fff;
  }

  .relation {
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px;
    background: #fff;
  }

  .choose {
    position: relative;
    float: left;
    width: 70px;
    height: 70px;
    margin-right: 20px;
    text-align: center;
    background: #fafafa;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .choose-add {
    float: left;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: 2px dashed rgba(240, 240, 240, 100);
    cursor: pointer;

    i {
      color: #5c5c5c;
      font-size: 30px;
    }
  }

  .box {
    display: flex;

    .left {
      width: 140px;
    }

    .right {
      width: 600px;
    }
  }

  .title {
    font-size: 16px;
  }

  .stitle {
    font-size: 14px;
  }

  .required {
    color: red;
  }

  .foot {
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    height: 60px;
    padding: 12px 20px;
    background: #fff;

    :global {
      .ant-btn {
        height: 36px;
        line-height: 36px;
      }
    }

    .clear {
      margin-left: 10px;
    }
  }
}
</style>
