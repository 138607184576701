<template>
  <div :class="$style.avatar">
    <div v-if="src" :class="$style.box" :style="style">
      <img :src="src" alt="" />
      <div :class="$style.bg">
        <a-upload
          name="file"
          :action="BASE_URL + '/api/cultural/file/upload'"
          :headers="headers"
          :show-upload-list="false"
          accept="image/jpg, image/jpeg, image/png"
          @change="handleChange"
        >
          <i :class="$style.edit" class="ri-edit-2-line" />
        </a-upload>
      </div>
    </div>
    <div v-else :class="$style.add">
      <a-upload
        name="file"
        :action="BASE_URL + '/api/cultural/file/upload'"
        :headers="headers"
        :show-upload-list="false"
        @change="handleChange"
      >
        <i class="ri-add-line" />
      </a-upload>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { BASE_URL } from '@/utils/variable'

export default {
  name: 'uploadAvatar',
  props: {
    src: {
      type: String,
      required: true,
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BASE_URL,
    }
  },
  computed: {
    ...mapState(['token']),
    headers() {
      return { Authorization: this.token }
    },
    style() {
      return {
        'border-radius': this.round ? '100%' : 'none',
      }
    },
  },
  methods: {
    handleChange(info) {
      const status = info.file.status
      if (status === 'done') {
        if (!info.file.response.code) {
          this.$message.error(info.file.response.error)
          return
        }
        this.$message.success('上传成功')
        // this.data.avatar = info.file.response.data.path
        this.$emit('complete', info.file.response.data.path)
      } else if (status === 'error') {
        this.$message.error('服务器异常')
      }
    },
  },
}
</script>

<style lang="less" module>
.avatar {
  .box {
    position: relative;
    width: 70px;
    height: 70px;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .edit {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      font-size: 20px;
      transform: translate(-50%, -50%);
    }
  }

  .box:hover .bg {
    display: block;
  }

  .add {
    width: 70px;
    height: 70px;
    line-height: 80px;
    text-align: center;
    border: 2px dashed #eee;
    cursor: pointer;

    i {
      color: #5c5c5c;
      font-size: 30px;
    }
  }
}
</style>
